let Run = window.Run;
let BSV = window.bsv;
// eslint-disable-next-line no-unused-vars
let Message = window.bsvMessage;
let Mnemonic = window.bsvMnemonic;
//import { useStore } from "vuex";
let payPath = "m/44'/236'/0'/1/0";
let dpath = "m/44'/236'/0'/2/0";
export function useRun() {
  let run = null;
  //let store = useStore();

  const loginWithSeed = async (_seed, store) => {
    let _run = null;
    let mnemonic = null;
    if (_seed === "") {
      mnemonic = new Mnemonic(Mnemonic.Words.ENGLISH);
      _seed = mnemonic.toString();
    } else {
      mnemonic = Mnemonic.fromString(_seed);
    }
    const hdPrivKey = BSV.HDPrivateKey.fromSeed(mnemonic.toSeed());
    const payKey = hdPrivKey.deriveChild(payPath);
    const ownerKey = hdPrivKey.deriveChild(dpath);
    const payPubKey = payKey.publicKey;
    const ownerPubKey = ownerKey.publicKey;
    const ownerAddress = ownerPubKey.toAddress();
    const payAddress = payPubKey.toAddress();
    console.log(
      payKey.privateKey.toString(),
      payAddress.toString(),
      ownerKey.privateKey.toString(),
      ownerAddress.toString()
    );
    _run = new Run({
      trust: "*",
      purse: payKey.privateKey.toString(),
      owner: ownerKey.privateKey.toString(),
    });
    let _userPurseKey = payKey.privateKey.toString();
    let _userPurseAddress = payAddress.toString();
    let _userOwnerKey = ownerKey.privateKey.toString();
    let _userOwnerAddress = ownerAddress.toString();
    await store.dispatch("login", {
      _seed,
      _userPurseKey,
      _userPurseAddress,
      _userOwnerKey,
      _userOwnerAddress,
    });
    console.log(_run.owner.address);
    run = _run;
    return run;
  };

  const setupAnonAccount = () => {
    let mnemonic = new Mnemonic(Mnemonic.Words.ENGLISH);
    console.log(mnemonic.toSeed());
    const ownerHdPrivKey = BSV.HDPrivateKey.fromSeed(
      mnemonic.toSeed(),
      "mainnet"
    );
    const childKey = ownerHdPrivKey.deriveChild(dpath);
    let ownerKey = childKey.privateKey;
    let ownerAddress = BSV.Address.fromPrivateKey(ownerKey);
    return {
      _seed: mnemonic.toSeed(),
      _userPurseKey: "",
      _userPurseAddress: "",
      _userOwnerKey: ownerKey.toString(),
      _userOwnerAddress: ownerAddress,
    };
  };

  const lock = async (message) => {
    // let LockClass = await run.load("lock class location");
    // let post = new Lock(message);
    // await post.sync();
    return message;
  };

  return { run, loginWithSeed, lock, setupAnonAccount };
}
