import firebase from "firebase/compat/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import "firebase/compat/firestore";
import { ref, onUnmounted } from "vue";
// import  {config}  from './../config'
import { useStore } from "vuex";

const firebaseConfig = {
  apiKey: "AIzaSyBmsWrILQoPrACmKJJq22lOnuWUXdvo28Q",
  authDomain: "vx-corp-demo.firebaseapp.com",
  projectId: "vx-corp-demo",
  storageBucket: "vx-corp-demo.appspot.com",
  messagingSenderId: "986869729116",
  appId: "1:986869729116:web:88ba63abe702ec68082399",
  measurementId: "G-S0Y78RNGCD",
};
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const locksRaw = firestore.collection("locks");
const locksCollection = firestore
  .collection("locks")
  .orderBy("createdAt", "desc");
const userProfilesCollection = firestore.collection("userProfiles");

export function useFirebase(_userId, _address) {
  const locks = ref([]);
  const auth = getAuth();
  console.log(auth);
  const unsubscribe = locksCollection
    .where("userId", "==", _userId)
    .onSnapshot((snapshot) => {
      locks.value = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    });
  onUnmounted(unsubscribe);

  const addLock = async (_message, _txId) => {
    console.log("About to lock", _userId, _address, _message, _txId);
    let response = await locksRaw.add({
      userId: _userId,
      address: _address,
      message: _message,
      txID: _txId,
      pubkey: "",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return response;
  };

  const fetchLock = async (_id) => {
    const store = useStore();
    let lock = ref([]);
    console.log("lockId before query", _id);

    await locksCollection.where("id", "==", _id).onSnapshot((snapshot) => {
      lock.value = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });
    console.log("Returning Profile:", lock.value);
    store.commit("setUserProfile", lock);
    return lock;
  };
  const fetchLockByTXID = async (_txid) => {
    let lock = ref([]);
    console.log("location before query", _txid);

    await locksCollection.where("_txId", "==", _txid).onSnapshot((snapshot) => {
      lock.value = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });
    console.log("Returning Lock:", lock.value);
    return lock;
  };

  return { locks, addLock, fetchLock, fetchLockByTXID };
}

export function useProfiles() {
  const auth = getAuth();
  console.log(auth);
  const addProfile = async (
    _userId,
    _name,
    _company,
    _encryptedSeed,
    _signatureName
  ) => {
    let response = await userProfilesCollection.add({
      userId: _userId,
      displayName: _name,
      company: _company,
      encryptedSeed: _encryptedSeed,
      signatureName: _signatureName,
    });
    return response;
  };

  const fetchProfile = async (_userId) => {
    const store = useStore();
    let profiles = ref([]);
    console.log("userId before query", _userId);

    await userProfilesCollection
      .where("userId", "==", _userId)
      .onSnapshot((snapshot) => {
        profiles.value = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      });
    console.log("Returning Profile:", profiles.value);
    store.commit("setUserProfile", profiles);
    return { profiles };
  };

  const updateProfile = async (profileId, _name, _company, _signatureName) => {
    userProfilesCollection.doc(profileId).update({
      displayName: _name,
      company: _company,
      signatureName: _signatureName,
    });
  };
  return { addProfile, fetchProfile, updateProfile };
}

export function useAuth() {
  const register = async (_email, _password, _name) => {
    console.log(_email, _password, _name);
    const auth = getAuth();
    let response = await createUserWithEmailAndPassword(
      auth,
      _email,
      _password
    );
    console.log("User:", response.user, "Error:", response.error);
    return response;
  };

  const signIn = async (email, password) => {
    console.log(email, password);
    let response;
    const auth = getAuth();
    response = await signInWithEmailAndPassword(auth, email, password);
    return response;
  };

  return { register, signIn };
}
