<template>
  <Menu />
  <!-- Header -->
  <div class="bg-gray-800">
    <div
      class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between"
    >
      <div class="max-w-xl">
        <h2
          class="text-left text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl"
        >
          Profile
        </h2>
        <p class="text-left mt-5 text-xl text-gray-400">
          You must save your Account Recovery Key. Without it, if you lose your
          password, we can not reset it.
        </p>
        <div class="py-4 sm:py-5">
          <dt class="text-sm font-medium font-bold text-gray-100 text-left">
            Account Recovery Key
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input
              class="w-full ring-2 ring-gray-200 rounded bg-white"
              :value="userSeed"
            />
            <!-- <button @click="updateSeed">Update</button> -->
          </dd>
        </div>
      </div>
    </div>
  </div>
  <!-- End Header -->
  <div class="text-left p-2 m-2 bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">Your Profile</h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        Personal details and application.
      </p>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Email Address</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <div class="w-full rounded">
              {{ user.email }}
            </div>
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Blockchain ID</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ userOwnerAddress }}
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Signing Key</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ userOwnerKey }}
          </dd>
        </div>
        <div
          v-if="!isEdit"
          class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Display Name</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input
              class="w-full ring-2 ring-gray-200 rounded"
              :value="profileDisplayName"
              disabled
            />
          </dd>
        </div>
        <div
          v-else
          class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Display Name</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input
              class="w-full ring-2 ring-gray-200 rounded bg-gray-200"
              v-model="edit_displayName"
            />
          </dd>
        </div>
        <div
          v-if="isEdit"
          class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Organization</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input
              class="w-full ring-2 ring-gray-200 rounded bg-gray-200"
              v-model="edit_Company"
            />
          </dd>
        </div>
        <div
          v-else
          class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Organization</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input
              class="w-full ring-2 ring-gray-200 rounded"
              :value="profileOrganizationDisplay"
              disabled
            />
          </dd>
        </div>
        <!-- Name Sig -->
        <!-- <div
          v-if="isEdit"
          class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Name For Signature</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input
              v-model="edit_signatureName"
              class="w-full ring-2 ring-gray-200 rounded"
            />
          </dd>
        </div>
        <div
          v-else
          class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Name For Signature</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input
              v-model="profileSignatureDisplay"
              class="w-full ring-2 ring-gray-200 rounded"
              disabled
            />
          </dd>
        </div> -->
        <!-- End Name Sig -->
        <div class="flex col-span-3 justify-end w-full">
          <div v-if="isEdit">
            <div class="flex items-right space-x-1">
              <div>
                <button
                  @click="isEdit = false"
                  class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  @click="save"
                  class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <button
              @click="setEditable"
              class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              Edit
            </button>
          </div>
        </div>
        <!-- <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Phone Number</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input class="w-full ring-2 ring-gray-200 rounded" />
          </dd>
        </div> -->
        <!-- <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">About</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
            incididunt cillum culpa consequat. Excepteur qui ipsum aliquip
            consequat sint. Sit id mollit nulla mollit nostrud in ea officia
            proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit
            deserunt qui eu.
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Attachments</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <ul
              role="list"
              class="border border-gray-200 rounded-md divide-y divide-gray-200"
            >
              <li
                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
              >
                <div class="w-0 flex-1 flex items-center">
                  <PaperClipIcon
                    class="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span class="ml-2 flex-1 w-0 truncate">
                    resume_back_end_developer.pdf
                  </span>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <a
                    href="#"
                    class="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Download
                  </a>
                </div>
              </li>
              <li
                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
              >
                <div class="w-0 flex-1 flex items-center">
                  <PaperClipIcon
                    class="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span class="ml-2 flex-1 w-0 truncate">
                    coverletter_back_end_developer.pdf
                  </span>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <a
                    href="#"
                    class="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Download
                  </a>
                </div>
              </li>
            </ul>
          </dd>
        </div> -->
      </dl>
    </div>
  </div>
</template>

<script>
import Menu from "./../components/LoggedInMenu.vue";
//import { PaperClipIcon } from "@heroicons/vue/solid";
import { reactive, toRefs } from "vue";
import { mapState, useStore } from "vuex";
import { useRouter } from "vue-router";
import { useProfiles } from "./../composition/useFirebase.js";
export default {
  components: {
    //PaperClipIcon,
    Menu,
  },
  async setup() {
    let router = useRouter();
    let store = useStore();
    let { addProfile, fetchProfile, updateProfile } = useProfiles();
    let _name =
      store.state.signatureName === "" ? "" : store.state.signatureName;
    if (store.state.user === null || store.state.user === "") {
      router.push("/login");
    }
    await fetchProfile(store.state.user.uid);
    const state = reactive({
      displayName: "",
      company: "Blockchain U",
      count: 0,
      signatureName: _name,
      encryptedSeed: "",
      isEdit: false,
      edit_displayName: "",
      edit_Company: "",
      edit_SignatureName: "",
    });

    return {
      ...toRefs(state),
      addProfile,
      fetchProfile,
      updateProfile,
    };
  },
  methods: {
    async save() {
      this.$store.commit("setSignatureName", this.signatureName);
      console.log(this.$store.state.user.uid);
      console.log(this.$store.state.userProfile[0]?.id);
      if (this.$store.state.userProfile[0]?.id) {
        try {
          await this.updateProfile(
            this.$store.state.userProfile[0].id,
            this.edit_displayName,
            this.edit_Company,
            this.edit_signatureName
          );
          this.isEdit = false;
        } catch (err) {
          alert("An Error Occurred Saving Your Profile: ", err);
        }
      } else {
        try {
          await this.addProfile(
            this.$store.state.user.uid,
            this.edit_displayName,
            this.edit_Company,
            this.$store.state.userSeed,
            this.edit_signatureName
          );
          this.isEdit = false;
        } catch (err) {
          alert("An Error Occurred Adding Your Profile: ", err);
        }
      }
    },
    setEditable() {
      this.edit_displayName = this.profileDisplayName;
      this.edit_Company = this.profileOrganizationDisplay;
      this.edit_signatureName = this.profileSignatureDisplay;
      this.isEdit = true;
    },
  },
  computed: {
    profileDisplayName() {
      console.log(this.$store.state.userProfile[0]?.displayName);
      if (!this.$store.state.userProfile[0]?.displayName) {
        return "Anonymous";
      }
      return this.$store.state.userProfile[0]?.displayName;
    },
    profileOrganizationDisplay() {
      console.log(this.$store.state.userProfile[0]?.company);
      if (!this.$store.state.userProfile[0]?.company) {
        return "Anonymous";
      }
      return this.$store.state.userProfile[0]?.company;
    },
    profileSignatureDisplay() {
      console.log(this.$store.state.userProfile[0]?.signatureName);
      if (!this.$store.state.userProfile[0]?.signatureName) {
        return "Anonymous";
      }
      return this.$store.state.userProfile[0]?.signatureName;
    },
    ...mapState([
      "user",
      "userSeed",
      "userOwnerKey",
      "userOwnerAddress",
      "userPurseKey",
      "userPurseAddress",
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
