<template>
  <logged-in-layout>
    <main class="flex-1 pb-8">
      <!-- Page header -->
      <div class="bg-white shadow">
        <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div
            class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200"
          >
            <div class="flex-1 min-w-0">
              <!-- Profile -->
              <div class="flex items-center">
                <!-- <img
                  class="hidden h-16 w-16 rounded-full sm:block"
                  src="https://images.unsplash.com/photo-1566753323558-f4e0952af115?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1921&q=80"
                  alt=""
                /> -->
                <LockClosedIcon class="h-16 w-16 sm:block" />
                <div>
                  <div class="flex items-center">
                    <!-- <img
                      class="h-16 w-16 rounded-full sm:hidden"
                      src="https://images.unsplash.com/photo-1566753323558-f4e0952af115?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1921&q=80"
                      alt=""
                    /> -->
                    <LockClosedIcon class="h-16 w-16 sm:hidden" />
                    <h1
                      class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate"
                    >
                      Hi, {{ userProfile.displayName }}
                    </h1>
                  </div>
                  <dl
                    class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap"
                  >
                    <dt class="sr-only">Organization</dt>
                    <dd
                      class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6"
                    >
                      <OfficeBuildingIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {{ userProfile.company }}
                    </dd>
                    <dt class="sr-only">Account status</dt>
                    <dd
                      class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize"
                    >
                      <CheckCircleIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                      <!-- Verified account -->
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <button
                @click="premiumAlert"
                type="button"
                class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Drafts
              </button>
              <button
                @click="navigateTo('/new')"
                type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                New Lock
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8">
        <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 class="text-lg leading-6 font-medium text-gray-900">Overview</h2>
          <div
            class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3"
          >
            <!-- Card -->
            <div
              v-for="category in categories"
              :key="category.name"
              class="bg-white overflow-hidden shadow rounded-lg"
            >
              <div class="p-5">
                <div class="flex items-center">
                  <div class="flex-shrink-0">
                    <component
                      :is="category.icon"
                      class="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="ml-5 w-0 flex-1">
                    <dl>
                      <dt class="text-sm font-medium text-gray-500 truncate">
                        {{ category.name }}
                      </dt>
                      <dd>
                        <div class="text-lg font-medium text-gray-900">
                          {{ category.amount }}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-5 py-3">
                <div class="text-sm">
                  <a
                    v-if="category.href !== '#'"
                    :href="category.href"
                    class="font-medium text-purple-700 hover:text-purple-900"
                  >
                    View all
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2
          class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8"
        >
          Recent Locks
        </h2>
        <!-- <div v-for="lock in locksToDisplay" :key="lock.id">
          {{ lock }}
        </div> -->
        <!-- Activity list (smallest breakpoint only) -->
        <div class="shadow sm:hidden">
          <ul
            role="list"
            class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden"
          >
            <li v-for="transaction in locksToDisplay" :key="transaction.id">
              <router-link
                :to="transaction.href"
                class="block px-4 py-4 bg-white hover:bg-gray-50"
              >
                <span class="flex items-center space-x-4">
                  <span class="flex-1 flex space-x-2 truncate">
                    <CashIcon
                      class="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span class="flex flex-col text-gray-500 text-sm truncate">
                      <span class="truncate">{{ transaction.name }}</span>
                      <span
                        ><span class="text-gray-900 font-medium">{{
                          transaction.amount
                        }}</span>
                        {{ transaction.currency }}</span
                      >
                      <time :datetime="transaction.datetime">{{
                        transaction.date
                      }}</time>
                    </span>
                  </span>
                  <ChevronRightIcon
                    class="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </router-link>
            </li>
          </ul>

          <nav
            class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
            aria-label="Pagination"
          >
            <div class="flex-1 flex justify-between">
              <a
                href="#"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Previous
              </a>
              <a
                href="#"
                class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Next
              </a>
            </div>
          </nav>
        </div>

        <!-- Activity table (small breakpoint and up) -->
        <div class="hidden sm:block">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-col mt-2">
              <div
                class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Title
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Content
                      </th>
                      <th
                        class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block"
                        scope="col"
                      >
                        Status
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr
                      v-for="transaction in locksToDisplay"
                      :key="transaction.id"
                      class="bg-white"
                    >
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                      >
                        <div class="flex">
                          <a
                            :href="transaction.href"
                            class="group inline-flex space-x-2 truncate text-sm"
                          >
                            <LockClosedIcon
                              class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            <p
                              class="text-gray-500 truncate group-hover:text-gray-900"
                            >
                              {{ transaction.name }}
                            </p>
                          </a>
                        </div>
                      </td>
                      <td
                        class="w-full px-6 py-4 text-left text-sm text-gray-500"
                      >
                        {{ transaction.content }}
                      </td>
                      <td
                        class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block"
                      >
                        <span
                          :class="[
                            statusStyles[transaction.status],
                            'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize',
                          ]"
                        >
                          {{ transaction.status }}
                        </span>
                      </td>
                      <td
                        class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                      >
                        <time :datetime="transaction.datetime">{{
                          transaction.date
                        }}</time>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- Pagination -->
                <nav
                  class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div class="hidden sm:block">
                    <p class="text-sm text-gray-700">
                      Showing
                      {{ " " }}
                      <span class="font-medium">{{ this.startIndex + 1 }}</span>
                      {{ " " }}
                      to
                      {{ " " }}
                      <span class="font-medium">{{ this.endIndex }}</span>
                      {{ " " }}
                      of
                      {{ " " }}
                      <span class="font-medium">{{ this.locks.length }}</span>
                      {{ " " }}
                      results
                    </p>
                  </div>
                  <div class="flex-1 flex justify-between sm:justify-end">
                    <button
                      @click="previousLocks"
                      class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Previous
                    </button>
                    <button
                      @click="nextLocks"
                      class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Next
                    </button>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </logged-in-layout>
</template>

<script>
import { ref, reactive, toRefs } from "vue";
import LoggedInLayout from "./layouts/LoggedInLayout.vue";
import { useFirebase, useProfiles } from "./../composition/useFirebase.js";
import { useStore, mapState } from "vuex";
import {
  LockClosedIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/solid";
import { useRun } from "./../composition/useRun";

const statusStyles = {
  published: "bg-green-100 text-green-800",
  draft: "bg-yellow-100 text-yellow-800",
  tagged: "bg-gray-100 text-gray-800",
};
export default {
  components: {
    LoggedInLayout,
    OfficeBuildingIcon,
    CheckCircleIcon,
    LockClosedIcon,
    ChevronRightIcon,
  },
  async setup() {
    const count = ref(0);
    const store = useStore();
    console.log("UID at setup:", store.state.user.uid);
    if (!store.state.user.uid) {
      if (window.location.toString().includes("localhost")) {
        window.location = "http://localhost:8080/login";
      } else {
        window.location = "https://www.lockthis.online/login";
      }
    }
    let { fetchProfile } = useProfiles();
    let { locks } = useFirebase(
      store.state.user.uid,
      store.state.userOwnerAddress
    );
    const state = reactive({
      startIndex: 0,
      endIndex: 10,
    });
    return { ...toRefs(state), count, locks, statusStyles, fetchProfile };
  },
  methods: {
    navigateTo(_path) {
      this.$router.push(_path);
    },
    extractTitle(lock) {
      let split = lock.message.split(":");
      return split[0];
    },
    previousLocks() {
      if (this.startIndex > 0) {
        this.startIndex = this.startIndex - 10;
      }
      if (this.endIndex > 39) {
        this.endIndex = this.endIndex - 10;
      }
    },
    nextLocks() {
      this.startIndex = this.startIndex + 10;
      this.endIndex = this.endIndex + 10;
      if (this.endIndex > this.locks.length) {
        this.endIndex = this.locks.length;
      }
    },
    premiumAlert() {
      alert("Premium Options Coming Soon");
    },
  },
  computed: {
    userProfile() {
      console.log("profile at lock2", this.$store.state.userProfile[0]);
      if (this.$store.state.userProfile[0]?.userId) {
        if (
          this.$store.state.userSeed ===
          this.$store.state.userProfile[0].encryptedSeed
        ) {
          return this.$store.state.userProfile[0];
        } else {
          console.log(
            "seed mismatch at lock2",
            this.$store.state.userProfile[0].encryptedSeed,
            this.$store.state.userSeed
          );
          let { loginWithSeed } = useRun();
          loginWithSeed(
            this.$store.state.userProfile[0].encryptedSeed,
            this.$store
          );
          return this.$store.state.userProfile[0];
        }
      }
      return { displayName: "Anonymous", company: "VX User Team" };
    },
    categories() {
      const _cards = [
        {
          name: "Published",
          href: "#",
          icon: CheckCircleIcon,
          amount: this.locks.length,
        },
        {
          name: "Drafts",
          href: "#",
          icon: QuestionMarkCircleIcon,
          amount: this.locks.filter((l) => {
            if (l.txid === "" || l.txid === null) {
              return l;
            }
          }).length,
        },
      ];
      return _cards;
    },
    locksToDisplay() {
      console.log("Locks before display Selection:", this.locks);
      let ordered = [...this.locks].sort((a, b) =>
        a.createdAt.seconds > b.createdAt.seconds ? -1 : 1
      );

      const transactions = [];
      ordered.forEach((i) => {
        let obj = {
          id: i.id,
          name: i.message.split(":")[0],
          href: `/lock-details?location=${i.txID}_o1`,
          content: i.message.split(":")[1].substring(0, 100),
          status: "published",
          date: new Date(i.createdAt.seconds * 1000).toLocaleDateString(),
          datetime: "2022-07-11",
        };
        transactions.push(obj);
      });
      console.log(this.startIndex, this.endIndex);
      return transactions.slice(this.startIndex, this.endIndex); //ordered.slice(this.startIndex, this.endIndex);
    },
    ...mapState(["userOwnerAddress", "userOwnerKey", "user", "signatureName"]),
  },
};
</script>
