import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LockView from "../views/LockView.vue";
import Lock2View from "../views/Lock2View.vue";
import LoginView from "../views/LoginView.vue";
import RegisterView from "../views/RegisterView.vue";
import LogoutView from "../views/LogoutView.vue";
import PaymentView from "../views/PaymentView.vue";
import PricingView from "../views/PricingView.vue";
import SuccessfulPayment from "../views/SuccessfulPayment.vue";
import ProfileView from "../views/ProfileView.vue";
import LockLaterView from "../views/ProfileView.vue";
import LockDetailsView from "../views/LockDetails.vue";
import MarkdownView from "./../views/tests/MarkdownView.vue";
import NewLock from "./../views/NewLock.vue";
import EncryptTest from "./../views/tests/EncryptTest.vue";
import Tools from "../views/ToolsView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/lock",
    name: "Lock",
    component: Lock2View,
  },
  {
    path: "/lock-old",
    name: "Lock Old",
    component: LockView,
  },
  {
    path: "/new",
    name: "New Lock",
    component: NewLock,
  },
  {
    path: "/lock-later",
    name: "Lock Later",
    component: LockLaterView,
  },
  {
    path: "/lock-details",
    name: "Lock Details",
    component: LockDetailsView,
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfileView,
  },
  {
    path: "/markdown",
    name: "Markdown",
    component: MarkdownView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutView,
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: PricingView,
  },
  {
    path: "/payments",
    name: "Payment",
    component: PaymentView,
  },
  {
    path: "/payment-success",
    name: "SuccessfulPayment",
    component: SuccessfulPayment,
  },
  {
    path: "/encrypt-test",
    name: "Encrypt Test",
    component: EncryptTest,
  },
  {
    path: "/tools",
    name: "Tools",
    component: Tools,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
