<template>
  <Menu></Menu>
  <div class="p-2 md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
      <h2
        class="text-gray-800 text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate"
      >
        Simple Encryption Tool
      </h2>
    </div>
  </div>
  <div class=""></div>
  <div class="bg-gray-100 space-y-1 flex">
    <div class="w-1/2 flex-1 space-y-2 p-2 m-2">
      <div class="text-xl">Encrypt</div>
      <div>
        <label> Enter the text you wish to encrypt </label>
        <textarea class="w-full rounded-xl" rows="5" v-model="message" />
      </div>
      <div>
        <label> 4 Character Secret Key</label><br />
        <input class="w-full rounded-xl" v-model="key" />
      </div>
      <div class="flex space-x-1">
        <div>
          <button
            @click="encrypt"
            class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Encrypt
          </button>
        </div>
        <div></div>
      </div>
    </div>
    <div class="w-1/2 flex-1 space-y-2 p-2 m-2">
      <div class="text-xl">Decrypt</div>
      <div>
        <label> Enter the Encrypted Message Text </label>
        <textarea v-model="encrypted" class="w-full rounded-xl" rows="5" />
      </div>
      <div>
        <label> 4 Character Secret Key</label><br />
        <input class="w-full rounded-xl" v-model="key" />
      </div>
      <div>
        <button
          @click="decrypt"
          class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Decrypt
        </button>
      </div>
    </div>
  </div>
  <div class="">
    <div class="relative py-16 bg-white overflow-hidden">
      <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          class="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            class="absolute top-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            class="absolute bottom-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg max-w-prose mx-auto">
          <h1>
            <span
              class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase"
              >Results</span
            >
            <span
              class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
              >Decrypted Text</span
            >
          </h1>
          <p class="mt-8 text-xl text-gray-500 leading-8 text-center">
            {{
              decrypted === ""
                ? "decrypted message will display here"
                : decrypted
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import Menu from "../components/LoggedInMenu.vue";
export default {
  components: { Menu },
  setup() {
    const state = reactive({
      message: "",
      key: "",
      encrypted: "",
      decrypted: "",
    });

    return {
      ...toRefs(state),
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
