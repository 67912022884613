<template>
  <logged-in-layout>
    <div class="lock-form p-4">
      <div class="relative">
        <div
          class="border border-gray-300 rounded-lg shadow-xl overflow-hidden focus-within:border-indigo-500 focus-within:ring-0.5 focus-within:ring-indigo-500"
        >
          <label for="title" class="sr-only">Title</label>
          <input
            type="text"
            v-model="name"
            name="title"
            id="title"
            class="block w-full border-0 pl-1 pt-2.5 text-md font-medium placeholder-gray-500 focus:ring-0"
            placeholder="Name Your Lock"
          />
          <label for="description" class="sr-only">Description</label>
          <textarea
            v-model="message"
            rows="12"
            name="description"
            id="description"
            class="block pl-1 w-full border-0 py-0 mb-3 resize-none placeholder-gray-500 focus:ring-0 sm:text-sm"
            placeholder="Type something you would like to document, and prove later."
          />

          <!-- Spacer element to match the height of the toolbar -->
          <div aria-hidden="true">
            <div class="py-2">
              <div class="h-9" />
            </div>
            <div class="h-px" />
            <div class="py-2">
              <div class="py-px">
                <div class="h-9" />
              </div>
            </div>
          </div>
        </div>

        <div class="absolute bottom-0 inset-x-px">
          <!-- Actions: These are just examples to demonstrate the concept, replace/wire these up however makes sense for your project. -->
          <div class="flex flex-nowrap justify-end space-x-2 sm:px-3">
            <Listbox as="div" v-model="labeled" class="" @click="premiumAlert">
              <ListboxLabel class="sr-only"> Add a label </ListboxLabel>
              <div class="relative">
                <ListboxButton
                  class="relative inline-flex items-center rounded-full py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3"
                >
                  <TagIcon
                    :class="[
                      labeled.value === null
                        ? 'text-gray-300'
                        : 'text-gray-500',
                      'flex-shrink-0 h-5 w-5 sm:-ml-1',
                    ]"
                    aria-hidden="true"
                  />
                  <span
                    :class="[
                      labeled.value === null ? '' : 'text-gray-900',
                      'hidden truncate sm:ml-2 sm:block',
                    ]"
                    >{{ labeled.value === null ? "Label" : labeled.name }}</span
                  >
                </ListboxButton>
              </div>
            </Listbox>

            <Listbox as="div" v-model="dated" class="" @click="premiumAlert">
              <ListboxLabel class="sr-only"> Add a due date </ListboxLabel>
              <div class="relative">
                <ListboxButton
                  class="relative disabled inline-flex items-center rounded-full py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3"
                >
                  <CalendarIcon
                    :class="[
                      dated.value === null ? 'text-gray-300' : 'text-gray-500',
                      'flex-shrink-0 h-5 w-5 sm:-ml-1',
                    ]"
                    aria-hidden="true"
                  />
                  <span
                    :class="[
                      dated.value === null ? '' : 'text-gray-900',
                      'hidden truncate sm:ml-2 sm:block',
                    ]"
                    >{{ dated.value === null ? "Due date" : dated.name }}</span
                  >
                </ListboxButton>
              </div>
            </Listbox>
          </div>
          <div
            class="border-t border-gray-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3"
          >
            <div class="flex">
              <button
                @click="premiumAlert"
                type="button"
                class="-ml-2 -my-2 rounded-full px-3 py-2 inline-flex items-center text-left text-gray-400 group"
              >
                <PaperClipIcon
                  class="-ml-1 h-5 w-5 mr-2 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                <span
                  class="text-sm text-gray-500 group-hover:text-gray-600 italic"
                  >Attach a file</span
                >
              </button>
            </div>
            <div class="flex-shrink-0">
              <!-- <button
                @click="createAJig"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-indigo-600 to-blue-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              > -->
              <button
                @click="createAJig"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-indigo-600 to-blue-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {{ loading ? "Saving..." : "Create" }}
              </button>
            </div>
          </div>
          <div>
            <p class="text-sm text-gray-600">
              Word Count: {{ message.split(" ").length }} / Character Count:
              {{ message.length }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="extra-configuration p-4 flex" @click="premiumAlert">
      <div
        class="p-2 m-2 bg-gray-800 w-full flex rounded-xl text-gray-100 mr-2"
      >
        <div class="pl-5 text-left w-full">Encrypt</div>
        <div>
          <Switch
            v-model="enableEncryption"
            :class="[
              enableEncryption ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            ]"
          >
            <span class="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              :class="[
                enableEncryption ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]"
            />
          </Switch>
        </div>
      </div>
      <div
        class="p-2 m-2 bg-gray-800 w-full flex rounded-xl text-gray-100 mr-2"
      >
        <div class="pl-5 text-left w-full">Generate New Address</div>
        <div @click="premiumAlert">
          <Switch
            v-model="enabled"
            :class="[
              enabled ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            ]"
          >
            <span class="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              :class="[
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]"
            />
          </Switch>
        </div>
      </div>
    </div>
    <div v-if="encrypted !== ''" class="extra-configuration p-4 flex">
      <div class="text-lg">Encrypted Text:</div>
      <p>{{ encrypted }}</p>
    </div>
    <div v-if="decrypted !== ''" class="extra-configuration p-4 flex">
      <div class="text-lg">Decrypted Text:</div>
      <p>{{ decrypted }}</p>
    </div>
  </logged-in-layout>
</template>

<script>
import { ref, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Switch } from "@headlessui/vue";
import { Listbox, ListboxButton, ListboxLabel } from "@headlessui/vue";
import { PayPurse } from "@fyxgaming/lib/dist/pay-purse";
import { useFirebase } from "./../composition/useFirebase.js";
import LoggedInLayout from "./layouts/LoggedInLayout.vue";

const labels = [
  { name: "Unlabelled", value: null },
  { name: "Engineering", value: "engineering" },
  // More items...
];
const dueDates = [
  { name: "No due date", value: null },
  { name: "Today", value: "today" },
  // More items...
];
const lockClassLocation =
  "a6fbd56e08319384853f8df664a0648343080a2efdf7d1d317957636e63842c3_o1";
export default {
  components: { LoggedInLayout, Switch, Listbox, ListboxButton, ListboxLabel },
  setup() {
    const store = useStore();
    const labeled = ref(labels[0]);
    const dated = ref(dueDates[0]);
    const enableEncryption = false;
    let _title = "";
    let _message = "";
    if (store.state.anonTitle !== "") {
      _title = store.state.anonTitle;
    }
    if (store.state.anonMessage !== "") {
      _message = store.state.anonMessage;
    }
    const state = reactive({
      count: 0,
      newSeed: "",
      name: _title,
      message: _message,
      locks: store.state.userLocks,
      selectedLock: null,
      enabled: false,
      //enableEncryption: false,
      loading: false,
      key: "1234",
      encrypted: "",
      decrypted: "",
      lastMessage: "",
    });

    return {
      ...toRefs(state),
      labeled,
      dated,
      enableEncryption,
    };
  },
  methods: {
    async createAJig() {
      let txid;
      let response;
      if (this.message.length > 2000) {
        alert(
          "Your message is too long, please upgrade to our pro-subscription"
        );
        return;
      }
      this.loading = true;
      let _run = new window.Run({
        trust: "*",
        purse: new PayPurse(
          "https://api.cryptofights.io",
          "!yQk2HwNin$NotsT4KbnPXrVrhV3g2PqUfCK"
        ), //"KwZw2apQ2HJnTbnN6f26YzmYhU9KEmiCrb7HaVDi2MFnXLq6aH38", //
        owner: this.$store.state.userOwnerKey,
      });
      let LockerClass = await _run.load(lockClassLocation);
      console.log(LockerClass);
      await _run.sync();
      await _run.inventory.sync();
      console.log(await _run.purse.address);
      //console.log(await _run.purse.balance());
      let tx = new window.Run.Transaction();
      //TODO: Make this JSON to string
      let messageToSave =
        this.name + ":" + this.message + ":" + new Date().toUTCString();
      let signKey = window.bsv.PrivateKey.fromString(_run.owner.privkey);
      let signedMessage = window.bsvMessage.sign(messageToSave, signKey);
      console.log(signedMessage);
      try {
        tx.update(() => {
          new LockerClass(
            messageToSave,
            signedMessage,
            _run.owner.address.toString()
          );
        });
        txid = await tx.publish();
        console.log("published", txid);
        let { addLock } = useFirebase(
          this.$store.state.user.uid,
          _run.owner.address
        );
        response = await addLock(messageToSave, txid);
        console.log({ response });
        this.message = "";
        this.name = "";
        this.$store.dispatch("clearLockBoxes");
        this.$router.push("/lock");
      } catch (err) {
        response = err;
        console.log({ err });
        alert(err);
      }
      this.loading = false;
      return response;
    },
    premiumAlert() {
      alert("Premium Options Coming Soon");
    },
    encrypt() {
      if (this.encrypted === "" || this.message !== this.lastMessage) {
        this.lastMessage = this.message;
        let messageToSave =
          this.name + ":" + this.message + ":" + new Date().toUTCString();
        let _k = this.key;
        var _key = window.CryptoJS.enc.Base64.parse("#" + _k + "#");
        var iv = window.CryptoJS.enc.Base64.parse("#base64IV#");
        var _encrypted = window.CryptoJS.AES.encrypt(messageToSave, _key, {
          iv: iv,
        });
        console.log(_encrypted.toString());
        this.encrypted = _encrypted.toString();
      } else {
        this.decrypt();
      }
    },
    decrypt() {
      //var _key = window.CryptoJS.enc.Base64.parse("#1234#");
      let _k = this.key;
      console.log(_k);
      var _key = window.CryptoJS.enc.Base64.parse("#" + _k + "#");
      var iv = window.CryptoJS.enc.Base64.parse("#base64IV#");
      var _decrypted = window.CryptoJS.AES.decrypt(this.encrypted, _key, {
        iv: iv,
      });
      this.decrypted = _decrypted.toString(window.CryptoJS.enc.Utf8);
      ``;
    },
  },
};
</script>

<style lang="scss" scoped></style>
