<template>
  <div class="flex">
    <div class="w-1/2">
      <textarea
        v-model="message"
        class="w-full bg-gray-100"
        rows="25"
      ></textarea>
    </div>
    <div class="w-1/2">
      <div class="clear">
        <Markdown :source="message" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import Markdown from "vue3-markdown-it";
export default {
  components: { Markdown },
  setup() {
    const state = reactive({
      count: 0,
      title: "",
      message: "",
      source: "# Hello World!",
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
html {
  /* Change default typefaces here */
  font-family: serif;
  font-size: 137.5%;
  -webkit-font-smoothing: antialiased;
}
/* Copy & Lists */
p {
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
}
ul,
ol {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
ul li,
ol li {
  line-height: 1.5rem;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-top: 0;
  margin-bottom: 0;
}
blockquote {
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  /* Change heading typefaces here */
  font-family: sans-serif;
  margin-top: 1.5rem;
  margin-bottom: 0;
  line-height: 1.5rem;
}
h1 {
  font-size: 4.242rem;
  line-height: 4.5rem;
  margin-top: 3rem;
}
h2 {
  font-size: 2.828rem;
  line-height: 3rem;
  margin-top: 3rem;
}
h3 {
  font-size: 1.414rem;
}
h4 {
  font-size: 0.707rem;
}
h5 {
  font-size: 0.4713333333333333rem;
}
h6 {
  font-size: 0.3535rem;
}
/* Tables */
table {
  margin-top: 1.5rem;
  border-spacing: 0px;
  border-collapse: collapse;
}
table td,
table th {
  padding: 0;
  line-height: 33px;
}
/* Code blocks */
code {
  vertical-align: bottom;
}
/* Leading paragraph text */
.lead {
  font-size: 1.414rem;
}
/* Hug the block above you */
.hug {
  margin-top: 0;
}
</style>
