<template>
  <logged-in-layout>
    <div
      class="text-center w-full text-4xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate"
    >
      Locked On Chain
    </div>
    <main class="flex-1 pb-8 w-full">
      <!-- Page header -->
      <div class="bg-white shadow w-full">
        <div class="px-4 sm:px-6 w-full lg:mx-auto lg:px-8">
          <div
            class="py-6 w-full md:flex md:items-start md:justify-between lg:border-t lg:border-gray-200"
          >
            <div class="flex-1 w-full">
              <!-- Profile -->
              <div class="flex items-center w-full">
                <!-- <img
                  class="hidden h-16 w-16 rounded-full sm:block"
                  src="https://images.unsplash.com/photo-1566753323558-f4e0952af115?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1921&q=80"
                  alt=""
                /> -->
                <div class="w-full">
                  <div class="flex items-center">
                    <!-- <img
                      class="h-16 w-16 rounded-full sm:hidden"
                      src="https://images.unsplash.com/photo-1566753323558-f4e0952af115?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1921&q=80"
                      alt=""
                    /> -->
                    <LockClosedIcon class="h-16 w-16" />
                    <h2
                      class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate"
                    >
                      {{ nftTitle }}
                    </h2>
                  </div>
                  <!-- <dl
                    class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap"
                  >
                    <dt class="sr-only">Organization</dt>
                    <dd
                      class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6"
                    >
                      <OfficeBuildingIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </dd>
                    <dt class="sr-only">Account status</dt>
                    <dd
                      class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize"
                    >
                      <CheckCircleIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    </dd>
                  </dl> -->
                  <div style="white-space: pre-line">{{ nftMessage }}</div>
                  <div class="bg-gray-200 w-full rounded-xl p-2 mt-4">
                    <div class="space-y-2 w-full">
                      <div
                        class="pt-2 mt-2 grid space-x-3 md:mt-0 w-full place-items-end"
                      >
                        <div>
                          <a
                            :href="whatsOnChainUrl()"
                            target="_blank"
                            class="items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-indigo-600 to-purple-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            View on Chain
                          </a>
                        </div>
                      </div>
                      <div class="text-left md:flex">
                        <div class="font-bold">Transaction ID:</div>
                        <div>
                          <p class="break-all">{{ txid }}</p>
                        </div>
                      </div>
                      <div class="text-left">
                        <span class="font-bold">Block #: </span
                        >{{ blockheight }}
                      </div>
                      <div class="text-left">
                        <span class="font-bold">Block Time:</span
                        >{{ blocktime }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </logged-in-layout>
</template>

<script>
import { reactive, toRefs } from "vue";
import LoggedInLayout from "./layouts/LoggedInLayout.vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useFirebase } from "./../composition/useFirebase.js";
import { useStore } from "vuex";
import {
  LockClosedIcon,
  // CheckCircleIcon,
  // ChevronRightIcon,
  // OfficeBuildingIcon,
  // QuestionMarkCircleIcon,
} from "@heroicons/vue/solid";
export default {
  components: { LoggedInLayout, LockClosedIcon },
  async setup() {
    let lock = null;
    let route = useRoute();
    let store = useStore();
    //let router = useRouter();
    let nft = null;
    let location = route.query.location;
    let message = "";
    let wocResponse = {
      data: { blocktime: 0, blockheight: 0, time: 0, size: 0, txid: 0 },
    };
    let _run = new window.Run({
      trust: "*",
    });
    console.log("User On Details", store.state.user.uid);
    if (!store.state.user) {
      console.log("Only this should execute");
    } else {
      console.log(route.query.location);
      let txid = location.split("_")[0];
      try {
        let { fetchLockByTXID } = useFirebase();
        console.log("TXID before fetch:", lock);
        lock = await fetchLockByTXID(txid);
        console.log("Lock Information:", lock);
      } catch (err) {
        console.log("Error getting lock:", err);
      }

      //}
      nft = await _run.load(location);
      console.log(nft);
      message = nft.message;
      let wocLink =
        "https://api.whatsonchain.com/v1/bsv/main/tx/hash/" +
        location.split("_")[0];
      wocResponse = await axios.get(wocLink);
      console.log(wocResponse.data);
    }

    const state = reactive({
      blockheight: wocResponse.data.blockheight
        ? wocResponse.data.blockheight
        : "0",
      blocktime: wocResponse.data.blocktime
        ? wocResponse.data.blocktime
        : "awaiting confirmation ...",
      txid: wocResponse.data.txid,
      time: wocResponse.data.time
        ? wocResponse.data.time
        : "awaiting confirmation ...",
      size: wocResponse.data.size
        ? wocResponse.data.size
        : "awaiting confirmation ...",
    });
    return {
      ...toRefs(state),
      location,
      message,
      nft,
    };
  },
  methods: {
    whatsOnChainUrl() {
      return "https://www.whatsonchain.com/tx/" + this.location.split("_")[0];
    },
  },
  computed: {
    nftTitle() {
      if (this.nft) {
        return this.nft.message.split(":")[0];
      } else {
        return "";
      }
    },
    nftMessage() {
      if (this.nft) {
        let sections = this.nft.message.split(":");
        //let count = 1;
        let last = sections.length - 4;
        let str = "";
        for (let x = 1; x <= last; x++) {
          str = str + sections[x];
        }
        return str; //this.nft.message.split(":")[1];
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
